import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { AdyenPaymentButton } from '@src/components/Buttons/AdyenPaymentButton';
import { Section } from '@src/components/Layout/Section';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import { useMember } from '@src/services/member/MemberProvider';
import { captureException } from '@src/utils/helpers/newRelic';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { DebtTransaction } from '@src/utils/hooks/api/types/transactions';
import { useBalanceSheet } from '@src/utils/hooks/api/useBalanceSheet';
import { useFetch } from '@src/utils/hooks/api/useFetch';

import { toLocal } from 'services/date-converter';

type MemberHasDebtProps = {
    optionalButton?: React.ReactNode;
    translations?: {
        debtStatusText: string;
    };
};

export const MemberHasDebt = ({ optionalButton, translations }: MemberHasDebtProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const member = useMember();
    // When the component is used in the public debt page, the id is passed as a parameter
    const params = useParams<{ id: string }>();
    const {
        data: debtTransactions,
        isError: debtTransactionsIsError,
        isLoading: debtTransactionsIsLoading
    } = useFetch<DebtTransaction[]>('/payments/debt-transactions');
    const balanceSheet = useBalanceSheet();
    const [noSupportToWebSocket, setNoSupportToWebSocket] = useState(false);
    const [transactions, setTransactions] = useState<DebtTransaction[]>([]);

    const { debtStatusText = '' } = translations || {};

    useEffect(() => {
        if (debtTransactionsIsError) {
            captureException('failed to fetch debt transactions', {
                component: 'MemberHasDebt'
            });
        }
    }, [debtTransactionsIsError]);

    /**
     * Find the current debt balance in the debt transactions
     * When the current balance is equal to the absolute value of the balance, we stop the loop
     */
    useEffect(() => {
        if (debtTransactions) {
            const total: number[] = [];
            const items = [];

            for (const item of debtTransactions) {
                total.push(item.amount);
                items.push(item);
                const currentBalance = total.reduce((acc, item) => acc + item, 0);
                if (Number(currentBalance.toFixed(2)) === Math.abs(balanceSheet.balance)) {
                    break;
                }
            }

            const logHistory = items.filter((item) => item.amount !== 0);
            setTransactions(logHistory);
        }
    }, [debtTransactions]);

    const peopleId = params.id || member?.state?.people_id_g;

    if (balanceSheet.isLoading) return null;
    if (balanceSheet.inDebt === false) return null;
    if (!peopleId) return null;

    return (
        <Section>
            <Box variant="mint-top" className="sm:-mb-xl">
                <Heading size={6}>{t('1968')}</Heading>
                <Text size={2} className="mt-xxs">
                    {debtStatusText || t('1969')}
                </Text>

                <Heading size={7} className="normal-case mt-l">{`${t('1970')}:`}</Heading>

                <div className="flex flex-col justify-between pt-xs">
                    <Heading size={3} className="md:text-[56px] flex-grow">{`${getPrice(
                        balanceSheet.balance,
                        language,
                        {
                            format: 'withDigits'
                        }
                    )}`}</Heading>
                    <div className="flex flex-col gap-2 mt-m">
                        <AdyenPaymentButton
                            className="flex-grow"
                            amount={balanceSheet.balance}
                            peopleId={peopleId}
                            setNoSupport={setNoSupportToWebSocket}
                        />
                        {optionalButton}
                    </div>
                </div>

                <Heading size={7} className="normal-case mt-l">{`${t('1971')}:`}</Heading>

                <div>
                    {/* see here which parts */}
                    <Text size={2} className="mb-xxs">
                        {t('1972')}
                    </Text>

                    {debtTransactionsIsLoading && <Skeleton type="text" lines={1} />}
                    {debtTransactionsIsError && <Text size={2}>{t('1527')}</Text>}
                    {transactions?.map((item) => (
                        <div
                            key={item.id}
                            className="border-solid border-b-[1px] border-grey py-xxs flex justify-between"
                        >
                            <Text size={2}>{`${item.comment} (${toLocal(item.dateTime)})`}</Text>
                            <Text size={2} className="font-bold ml-s">
                                {getPrice(item.amount, language, {
                                    format: 'withDigits'
                                })}
                            </Text>
                        </div>
                    ))}
                </div>

                <hr className="border-solid border-t-[1px] border-anthracite" />

                <div className="flex justify-between mt-xs">
                    <Heading size={7} className="normal-case">{`${t('445')}:`}</Heading>
                    <Heading size={7} className="normal-case">
                        {getPrice(balanceSheet.balance * -1, language, { format: 'withDigits' })}
                    </Heading>
                </div>

                {/** browser doesn't support websocket */}
                {noSupportToWebSocket && <Text size={1}>{t('474')}</Text>}
            </Box>
        </Section>
    );
};
